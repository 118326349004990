<template>
    <div>
        <div class="mb-4 d-flex">
            <b-button variant="light"
                      class="border w-40 h-40 mr-2"
                      :class="extraInfo ? null : 'collapsed'"
                      :aria-expanded="extraInfo ? 'true' : 'false'"
                      aria-controls="collapse-4"
                      @click="extraInfo = !extraInfo"
            >
                <i class="ri-arrow-down-s-line" v-if="!extraInfo"></i>
                <i class="ri-arrow-up-s-line" v-if="extraInfo"></i>
            </b-button>
            <div class="flex-grow-1 bg-light w-40 h-40 border rounded-sm d-flex align-items-center pl-3 font-weight-bold">
                {{ getObjectValue(minorApplication, 'student_number') }}, {{ getObjectValue(minorApplication, 'name') }}
            </div>
        </div>
        <b-collapse id="collapse-4" v-model="extraInfo">
            <div class="border rounded-sm p-4 mb-4">
                <b-row>
                    <b-col cols="auto">
                        <b-form-group :label="$t('faculty')">
                            <div class="label-as-input">
                                {{ getObjectValue(minorApplication, 'faculty_name') }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('program')">
                            <div class="label-as-input">
                                {{ getObjectValue(minorApplication, 'program_name') }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('class')">
                            <div class="label-as-input">
                                {{ minorApplication.class ? $t('class_x', {'class': minorApplication.class}) : '-' }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('number_of_semesters')">
                            <div class="label-as-input">
                                {{ getObjectValue(minorApplication, 'semester_count') }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('registration_type_1')">
                            <div class="label-as-input">
                                {{ getLocaleText(minorApplication,'registration_type_text','-') }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('registration_date')">
                            <div class="label-as-input">
                                {{ toLocaleDate(minorApplication.registration_date) }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('prep_status')">
                            <div class="label-as-input">
                                {{ getLocaleText(minorApplication,'prep_status_text','-') }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('equivalence_status')">
                            <div class="label-as-input">
                                {{ minorApplication.equivalence_status ? $t('there_is') : $t('none') }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('gno')">
                            <div class="label-as-input">
                                {{ getObjectValue(minorApplication, 'cgpa') }}
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </b-collapse>

        <div class="font-weight-medium mb-1">{{ $t('your_preferences').toUpper() }}</div>
        <b-table :empty-filtered-text="$t('no_result')"
                 :empty-text="$t('no_result')"
                 bordered
                 responsive
                 :items="minorApplicationPreferences"
                 :fields="minorApplicationPreferenceFields"
                 show-empty
                 class="mb-4 table-dropdown no-scrollbar border rounded"
        >
            <template #cell(faculty_program)="row">
                {{ row.item.faculty_name }} <br>{{ row.item.program_name }}
            </template>
            <template #cell(status)="row">
                <span class="badge badge-warning" v-if="row.item.status.includes('waiting')">
                    {{ row.item.status_text.toUpper() }}
                </span>
                <div v-else-if="row.item.status == 'declined'">
                    <span class="badge badge-danger">
                        {{ row.item.status_text.toUpper() }}
                    </span>
                    <span v-if="row.item.explanation"><br>{{ row.item.explanation }}</span>
                </div>
                <span class="badge badge-success" v-else-if="row.item.status == 'approved'">
                    {{ row.item.status_text.toUpper() }}
                </span>
            </template>
            <template #cell(process)="row">
                <div class="d-flex justify-content-center"
                     v-if="row.item.status=='waiting_prep_school'">
                    <b-button variant="primary"
                              size="sm"
                              @click="showStatusForm(row.item)">
                        {{ $t('edit') }}
                    </b-button>
                </div>
                <div class="text-center" v-else>
                    -
                </div>
            </template>
        </b-table>

        <CommonModal size="md"
                     ref="statusFormModal"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <ValidationObserver ref="formModalValidate">
                    <b-row>
                        <b-col cols="12">
                            <ValidationProvider name="status" rules="required"  v-slot="{valid, errors}">
                                <b-form-group :label="$t('status')">
                                    <b-form-select v-model="formData.status"
                                                   :options="statusOptions"
                                                   :state="errors[0] ? false : null"
                                    />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="prep_score" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('score')">
                                    <b-form-input
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="formData.prep_score"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                            <div v-show="formData.status=='declined'">
                                <ValidationProvider name="explanation" rules="required_if:status,declined" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('explanation')">
                                        <b-form-textarea v-model="formData.explanation"
                                                         :state="errors[0] ? false : null"
                                        />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                        </b-col>
                        <b-col cols="12" class="d-flex justify-content-center">
                            <b-button variant="primary" class="mt-0" @click="storeForm">
                                {{ $t('save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </template>
        </CommonModal>
    </div>
</template>

<script>
    // Component
    import CommonModal from '@/components/elements/CommonModal';

    // Services
    import MinorApplicationService from '@/services/MinorApplicationService'
    import MinorApplicationPreferenceService from '@/services/MinorApplicationPreferenceService'

    // Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"

    export default {
        components: {
            CommonModal,

            ValidationProvider,
            ValidationObserver,
        },
        props: {
            minorApplication: {
                type: Object
            }
        },
        data() {
            return {
                formLoading: false,

                minorApplicationId: null,
                minorApplicationPreferences: [],
                minorApplicationPreferenceFields: [
                    {
                        key: 'rank',
                        label: this.$t('rank').toUpper(),
                        tdClass: 'text-center align-middle',
                        thClass: 'text-center',
                        sortable: false
                    },
                    {
                        key: 'faculty_program',
                        label: this.$t('faculty').toUpper(),
                        tdClass: 'align-middle',
                        sortable: false
                    },
                    {
                        key: 'status',
                        label: this.$t('status').toUpper(),
                        tdClass: 'text-center align-middle',
                        thClass: 'text-center',
                        sortable: false
                    },
                    {
                        key: 'status',
                        label: this.$t('status').toUpper(),
                        tdClass: 'align-middle',
                        sortable: false
                    },
                    {
                        key: 'prep_score',
                        label: this.$t('prep_score').toUpper(),
                        tdClass: 'align-middle text-center',
                        thClass: 'text-center',
                        sortable: false
                    },
                    {
                        key: 'process',
                        label: this.$t('process').toUpper(),
                        tdClass: 'align-middle text-center',
                        thClass: 'text-center',
                        sortable: false
                    }
                ],

                formId: 0,
                formProcess: null,
                formData:{
                    minor_application_preference_id: null,
                    status: null,
                    explanation: null,
                    prep_score: null
                },
                statusOptions: [
                    { value: null, text: '-' },
                    { value: 'approved', text: this.$t('approved') },
                    { value: 'declined', text: this.$t('declined') },
                ],
                extraInfo: false
            }
        },
        created() {
            this.minorApplicationId = this.minorApplication.id;
            this.minorApplicationPreferences = this.minorApplication.minor_application_preferences
            this.minorApplication.minor_application_preferences.forEach((itm,index) => {
                this.minorApplicationPreferences[index].prep_score = this.minorApplication.prep_score
            })
        },
        methods: {
            async storeForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    let formData = {
                        minor_application_preference_id : this.formId,
                        status: this.formData.status,
                        prep_score: this.formData.prep_score,
                        explanation: this.formData.status=='declined' ? this.formData.explanation:null,
                    };

                    MinorApplicationPreferenceService.prepSchoolApprove(formData)
                                                     .then(response => {
                                                        this.getMinorApplication()
                                                        this.showStatusFormSuccess()
                                                        this.$emit('updated')
                                                     })
                                                     .catch(e => {
                                                         this.showErrors(e, this.$refs.formModalValidate);
                                                     })
                                                     .finally(() => {
                                                         this.formLoading = false
                                                     })
                }
            },
            getMinorApplication() {
                MinorApplicationService.get(this.minorApplicationId)
                                       .then(response => {
                                           let data = response.data.data;
                                           this.minorApplicationId = data.id;
                                           this.minorApplicationPreferences = data.minor_application_preferences
                                       })
                                       .catch(e => {
                                           this.showErrors(e);
                                       })
            },
            formClear() {
                this.formId = null
                this.formData = {
                    minor_application_preference_id: null,
                    status: null,
                    explanation: null,
                    prep_score: null
                }
                this.formProcess = null
            },
            showStatusForm(row) {
                this.formId = row.id
                this.formProcess = 'status'
                this.$refs.statusFormModal.$refs.commonModal.show()
            },
            showStatusFormSuccess() {
                this.$refs.statusFormModal.$refs.commonModal.hide()
            }
        }
    }
</script>
